import jwt from 'jwt-decode';
import JwtUser from '../interfaces/jwtUser';
import { NivelAcessoEnum } from '../utils/enum/nivelAcessoEnum';

export const TOKEN_KEY = "@gfl-Token";
export const isAuthenticated = () => localStorage.getItem(TOKEN_KEY) !== null;
export const getToken = () => localStorage.getItem(TOKEN_KEY);
export const getUser = (): JwtUser => {
  const token = getToken();

  if (token != null) {
    let usuario = jwt<JwtUser>(token)

    return usuario
  }

  return {} as JwtUser;
};
export const login = (token: string) => {
  localStorage.setItem(TOKEN_KEY, token);
};
export const logout = () => {
  localStorage.removeItem(TOKEN_KEY);
};

export const EsconderPorNivelAcesso = ({ children, nivelAcesso }: { children: JSX.Element, nivelAcesso: NivelAcessoEnum[] }) => {
  return nivelAcesso.some(item => getUser().role === item) ? children : null
}

export const DesabilitarCampoCrud = () => {
  //const user = getUser();

  // if (user.role === '2')
    //return true;

  return false;
}