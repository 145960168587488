import { useContext, useState, useEffect } from 'react'
import {
  Link,
  useNavigate,
  useLocation,
  Navigate,
  useOutlet,
} from "react-router-dom"
import { logout, getUser } from '../../../services/auth'
import {
  Box, ListItem, ListItemIcon, Button, Typography, Divider, ListItemAvatar, Avatar, Paper, IconButton
} from '@mui/material'
import LogoutIcon from '@mui/icons-material/Logout'
import MenuIcon from '@mui/icons-material/Menu'
import {
  Div,
  Container,
  Content,
  Menu,
  ListaOpcoesMenu,
  OpcaoMenuTexto,
  BotaoMenu,
  ListItemLogout,
  HeaderOptions,
  FooterOptions,
  Image,
  TituloContainer,
  IconRelatorio,
  IconImportarNfe,
  BotoesDeAcao
}
  from './styles'
import { GFLContext } from '../../../infra/GFLContext'
import LimaIconBlack from '../../../assets/lima-simbol-black.png'
import ProdutosIcon from '../../../assets/surfboard.png'
import CategoriasIcon from '../../../assets/clothes.png'
import ClientesIcon from '../../../assets/surfguy.png'
import UsuarioIcon from '../../../assets/user.png'
import FrenteCaixaIcon from '../../../assets/cash-register.png'
import VendedoresIcon from '../../../assets/vendedores.png'
import VendasIcon from '../../../assets/vendas-icon.png'
import { NivelAcessoEnum } from '../../../utils/enum/nivelAcessoEnum'
import obterDescricaoNivelAcesso from '../../../utils/enum/nivelAcessoDescricao'
import ReportDialog from '../../components/ReportDialog'
import ImportarNFeDialog from '../../components/ImportarNFeDialog'

function Main() {
  const [menu, setMenu] = useState<boolean>(false)
  const [relatorioAberto, setRelatorioAberto] = useState<boolean>(false)
  const [importarNfeAberto, setImportarNfeAberto] = useState<boolean>(false)
  const contexto = useContext(GFLContext)
  const navigate = useNavigate()
  const { pathname } = useLocation()
  const isFullWidth = pathname === "/frentedecaixa"
  const outlet = useOutlet()

  const usuario = getUser()

  var titulo = ''
  switch (pathname) {
    case "/frentedecaixa":
      titulo = "Frente de Caixa"
      break;
    case "/produtos":
      titulo = "Produtos"
      break
    case "/categorias":
      titulo = "Categorias"
      break
    case "/clientes":
      titulo = "Clientes"
      break
    case "/usuarios":
      titulo = "Usuários"
      break
    case "/vendas":
      titulo = "Vendas"
      break
    case "/vendedores":
      titulo = "Vendedores"
      break
    default:
      titulo = ''
      break
  }

  useEffect(() => {
    //navigate('/frentedecaixa')
  }, [])

  const toggleMenu = (aberto: boolean) => (
    e: any
  ) => {
    setMenu(aberto)
  }

  const handleBotaoLogout = () => {
    logout()
    navigate('/login')
  }

  const handleBotaoRelatorio = () => {
    setRelatorioAberto(true)
  }

  const conteudoMenu = () => (
    <Box
      role="presentation"
      height="100%"
    >
      <ListaOpcoesMenu>
        <Image width="150px" height="131px" src={LimaIconBlack} />
        <Divider />
        <ListItem divider={true} component={Link} to="/frentedecaixa" onClick={() => setMenu(false)}>
          <ListItemAvatar>
            <Avatar alt="frente-de-caixa" src={FrenteCaixaIcon} />
          </ListItemAvatar>
          <OpcaoMenuTexto primary={'Frente de Caixa'} />
        </ListItem>
        <ListItem divider={true} component={Link} to="/vendas" onClick={() => setMenu(false)}>
          <ListItemAvatar>
            <Avatar alt="vendas" src={VendasIcon} />
          </ListItemAvatar>
          <OpcaoMenuTexto primary={'Vendas'} />
        </ListItem>
        <ListItem divider={true} component={Link} to="/produtos" onClick={() => setMenu(false)}>
          <ListItemAvatar>
            <Avatar alt="produtos" src={ProdutosIcon} />
          </ListItemAvatar>
          <OpcaoMenuTexto primary={'Produtos'} />
        </ListItem>
        <ListItem divider={true} component={Link} to="/categorias" onClick={() => setMenu(false)}>
          <ListItemAvatar>
            <Avatar alt="categorias" src={CategoriasIcon} />
          </ListItemAvatar>
          <OpcaoMenuTexto primary={'Categorias'} />
        </ListItem>
        <ListItem divider={true} component={Link} to="/clientes" onClick={() => setMenu(false)}>
          <ListItemAvatar>
            <Avatar alt="clientes" src={ClientesIcon} />
          </ListItemAvatar>
          <OpcaoMenuTexto primary={'Clientes'} />
        </ListItem>
        {usuario.role === NivelAcessoEnum.admin || usuario.role === NivelAcessoEnum.gerente ? (
        <ListItem divider={true} component={Link} to="/vendedores" onClick={() => setMenu(false)}>
          <ListItemAvatar>
            <Avatar alt="vendedores" src={VendedoresIcon} />
          </ListItemAvatar>
          <OpcaoMenuTexto primary={'Vendedores'} />
        </ListItem>
        ) : null}
        {usuario.role === NivelAcessoEnum.admin || usuario.role === NivelAcessoEnum.gerente ? (
          <ListItem divider={true} component={Link} to="/usuarios" onClick={() => setMenu(false)}>
            <ListItemAvatar>
              <Avatar alt="usuarios" src={UsuarioIcon} />
            </ListItemAvatar>
            <OpcaoMenuTexto primary={'Usuários'} />
          </ListItem>
        ) : null}
        <ListItemLogout key="logout" component={Link} to="/login" onClick={handleBotaoLogout}>
          <ListItemIcon>
            <LogoutIcon />
          </ListItemIcon>
          <OpcaoMenuTexto primary={"Sair"} />
        </ListItemLogout>
      </ListaOpcoesMenu>
    </Box>
  )

  const conteudoApp = () => {
    if (!outlet)
      return <Navigate to={{ pathname: '/produtos' }} />

    return (
      <>
        {outlet}
        {relatorioAberto && <ReportDialog open={relatorioAberto} setOpen={setRelatorioAberto} />}
        {importarNfeAberto && <ImportarNFeDialog aberto={importarNfeAberto} setAberto={setImportarNfeAberto} />}
      </>
    )
  }

  const showReportButton = () => {
    if ((pathname === '/vendas' || pathname === '/frentedecaixa') && usuario.role !== '2') {
      return (
        <IconButton
          color="primary"
          onClick={handleBotaoRelatorio}
        >
          <IconRelatorio />
        </IconButton>
      )
    } else
      return <div></div>
  }

  const showImportNfeButton = () => {
    return (
      <IconButton
        color="primary"
        onClick={() => setImportarNfeAberto(true)}
      >
        <IconImportarNfe />
      </IconButton>
    )
    // if (usuario.role !== '2') {
      
    // } else
    //   return <div></div>
  }

  return (
    <>
      <Div>
        <Container fullWidth={isFullWidth}>
          <HeaderOptions>
            <BotaoMenu onClick={_ => setMenu(!menu)}>
              <MenuIcon />
            </BotaoMenu>
            <TituloContainer>
              <Typography variant="h4" color="initial">{titulo}</Typography>
            </TituloContainer>
            <BotoesDeAcao>
              {showImportNfeButton()}
              {showReportButton()}
            </BotoesDeAcao>
          </HeaderOptions>
          <Content square fullWidth={isFullWidth}>
            <Menu
              anchor="left"
              open={menu}
              onOpen={toggleMenu(true)}
              onClose={toggleMenu(false)}
            >
              {conteudoMenu()}
            </Menu>
            {conteudoApp()}
          </Content>
          {/* </div> */}
          <FooterOptions>
            <div></div>
            <Typography variant="h5" color="initial">{usuario.unique_name} - {obterDescricaoNivelAcesso(usuario.role)}</Typography>
            <div></div>
          </FooterOptions>
        </Container>
      </Div>

    </>
  )
}

export default Main