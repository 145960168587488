import React, { useState, useEffect } from 'react'
import { IconButton, TextField, Divider, debounce } from '@mui/material'
import SearchIcon from '@mui/icons-material/Search'
import AddCircleIcon from '@mui/icons-material/AddCircle'
import { GridColDef, GridRowParams, GridValueGetterParams, ptBR } from '@mui/x-data-grid'
import Produto from '../../../interfaces/produto'
import ProdutoDialog from '../../components/ProdutoDialog'
import axios from '../../../services/withAxios';
import Surfboard from '../../../assets/surfboard.png'
import { Header, Content, Image, StyledDataGrid } from './styles'
import { EsconderPorNivelAcesso, getUser } from '../../../services/auth'
import { NivelAcessoEnum } from '../../../utils/enum/nivelAcessoEnum'

const colunas: GridColDef[] = [
  {
    field: 'codigo',
    headerName: 'Código',
    width: 100,
    valueGetter: (getter: GridValueGetterParams) => {
      return getter.value === '' ? '-' : getter.value
    }
  },
  {
    field: 'nome',
    headerName: 'Nome',
    flex: 1
  },
  {
    field: 'valor',
    headerName: 'Valor',
    width: 100,
    valueFormatter: (params: any) => {
      return new Intl.NumberFormat('pt-BR', {
        style: 'currency',
        currency: 'BRL'
      }).format(params.value)
    },
    align: "right"
  },
  {
    field: 'categoriaNome',
    headerName: 'Categoria',
    width: 200
  }
]

const Produtos: React.FC = () => {
  const [pesquisa, setPesquisa] = useState<string>('')
  const [dialogAberto, setDialogAberto] = useState<boolean>(false)
  const [produtos, setProdutos] = useState<Produto[]>([])
  const [produtoSelecionado, setProdutoSelecionado] = useState<Produto | null>(null)
  const [pesquisarTrigger, setPesquisarTrigger] = useState<boolean>(false);

  const user = getUser();

  const pesquisar = debounce((event: React.ChangeEvent<HTMLInputElement>) => {
    setPesquisa(event.target.value);
  }, 500)

  useEffect(() => {
    if (dialogAberto)
      return

    const buscarDados = async () => {
      if (pesquisa === '')
        await axios('/produto')
          .then(resp => {
            if (resp)
              setProdutos(resp.data === '' ? [] : resp.data)
          })
      else
        await axios(`/produto/search/${pesquisa}`)
          .then(resp => {
            if (resp)
              setProdutos(resp.data === '' ? [] : resp.data)
          })
    }

    buscarDados()

    return () => {
      setProdutos([])
    }
  }, [pesquisa, pesquisarTrigger, dialogAberto])

  const handleAdicionar = () => {
    setProdutoSelecionado(null)
    setDialogAberto(!dialogAberto)
  }

  const handleClickLinha = (params: GridRowParams) => {
    setProdutoSelecionado(params.row as Produto)
    setDialogAberto(true)
  }

  return (
    <>
      <Image src={Surfboard} />
      <Header>
        <TextField
          id="pesquisa"
          label="Pesquisar"
          onChange={pesquisar}
          type="search"
          fullWidth={true}
          autoComplete='off'
          autoFocus
          inputProps={{ style: { textTransform: "uppercase" } }}
        />
        <IconButton
          color="primary"
          onClick={_ => setPesquisarTrigger(!pesquisarTrigger)}
        >
          <SearchIcon />
        </IconButton>
        <EsconderPorNivelAcesso nivelAcesso={[NivelAcessoEnum.admin, NivelAcessoEnum.gerente, NivelAcessoEnum.usuario]}>
          <>
            <Divider orientation="vertical" variant="middle" flexItem />
            <IconButton
              onClick={handleAdicionar}
              color="primary"
            >
              <AddCircleIcon />
            </IconButton>
          </>
        </EsconderPorNivelAcesso>
      </Header>
      <Content>
        <StyledDataGrid
          localeText={ptBR.components.MuiDataGrid.defaultProps.localeText}
          columns={colunas}
          rows={produtos}
          disableColumnMenu={true}
          hideFooterSelectedRowCount={true}
          onRowClick={handleClickLinha}
          sx={{
            textTransform: 'uppercase'
          }}
        />
      </Content>
      {dialogAberto ? <ProdutoDialog aberto={dialogAberto} setAberto={setDialogAberto} entidade={produtoSelecionado} /> : <></>}
    </>
  )
}

export default Produtos