import React, { useState, useEffect } from 'react'
import {
    Dialog,
    DialogContent,
    DialogTitle,
    useMediaQuery,
    Select,
    InputLabel,
    MenuItem,
    Alert,
} from '@mui/material'
import { useTheme } from '@mui/material/styles'
import { Content, Campo, FormControlEspacado, InputLabelBranco } from './styles'
import axios from '../../../services/withAxios';
import Categoria from '../../../interfaces/categoria'
import Produto from '../../../interfaces/produto'
import PopupProps from '../../../interfaces/popupProps';
import ButtonsCrud from '../ButtonsCrud';
import { DesabilitarCampoCrud, EsconderPorNivelAcesso } from '../../../services/auth';
import { NivelAcessoEnum } from '../../../utils/enum/nivelAcessoEnum';
import { NumberFormatValues } from 'react-number-format';
import CurrencyInput from '../CurrencyInput';

const ProdutoDialog: React.FC<PopupProps<Produto>> = ({ aberto, setAberto, entidade }) => {
    const [codigo, setCodigo] = useState<string>('')
    const [codigoDeBarra, setCodigoDeBarra] = useState<string>('')
    const [nome, setNome] = useState<string>('')
    const [descricao, setDescricao] = useState<string>('')
    const [valor, setValor] = useState<number>(0)
    const [valorCompra, setValorCompra] = useState<number>(0)
    const [categoriaSelecionada, setCategoriaSelecionada] = useState<string>()

    const [categorias, setCategorias] = useState<Categoria[]>()

    const theme = useTheme()
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'))

    const handleFechar = () => {
        setAberto(false)
    }

    const handleSalvar = (e: React.SyntheticEvent) => {
        e.preventDefault()
        if (entidade) {
            axios.put('/produto', {
                id: entidade?.id,
                codigo: codigo.toUpperCase(),
                codigoDeBarra: codigoDeBarra.toUpperCase(),
                nome: nome.toUpperCase(),
                descricao: descricao.toUpperCase(),
                valor: valor as number,
                valorCompra: valorCompra as number,
                categoriaId: categoriaSelecionada ?? '',
            } as Produto)
                .then(resp => {
                    if (resp.status === 200)
                        setAberto(false)
                })
        } else {
            axios.post('/produto', {
                codigo: codigo.toUpperCase(),
                codigoDeBarra: codigoDeBarra.toUpperCase(),
                nome: nome.toUpperCase(),
                descricao: descricao.toUpperCase(),
                valor: valor as number,
                valorCompra: valorCompra as number,
                categoriaId: categoriaSelecionada ?? '',
            } as Produto)
                .then(resp => {
                    if (resp.status === 200)
                        setAberto(false)
                })
        }
    }

    useEffect(() => {
        const buscarCategorias = async () => {
            const dados = await axios.get('/categoria', { params: { avoidLoadingScreen: true } })
            setCategorias(dados.data)
        }

        if (entidade) {
            setCodigo(entidade.codigo)
            setNome(entidade.nome)
            setCodigoDeBarra(entidade.codigoDeBarra)
            setDescricao(entidade.descricao)
            setValor(entidade.valor)
            setValorCompra(entidade.valorCompra)
            setCategoriaSelecionada(entidade.categoriaId)
        }

        buscarCategorias()
    }, [aberto, entidade])

    const handleDeletar = () => {
        if (entidade?.id) {
            axios.delete('/produto/' + entidade.id).then(resp => {
                if (resp.status === 200)
                    setAberto(false)
                else {
                    return <Alert severity="error">Erro ao deletar produto</Alert>
                }
            })
        }
    }

    return (
        <Dialog
            fullScreen={fullScreen}
            open={aberto}
            onClose={handleFechar}
        >
            <DialogTitle id="produto-dialog">
                {"Produto"}
            </DialogTitle>
            <DialogContent>
                <Content id="formProduto" component="form" onSubmit={handleSalvar}>
                    <Campo
                        id="codigo"
                        label="Código"
                        value={codigo} 
                        onChange={(e) => setCodigo(e.target.value)}
                        fullWidth
                        autoComplete='off'
                        inputProps={{ style: { textTransform: "uppercase" } }}
                        autoFocus
                        disabled={DesabilitarCampoCrud()}
                    />
                    <Campo
                        id="codigo-barra"
                        label="Código de Barras"
                        value={codigoDeBarra}
                        onChange={(e) => setCodigoDeBarra(e.target.value)}
                        fullWidth
                        inputProps={{ style: { textTransform: "uppercase" } }}
                        autoComplete='off'
                        disabled={DesabilitarCampoCrud()}
                    />
                    <Campo
                        id="nome"
                        label="Nome"
                        value={nome}
                        onChange={(e) => setNome(e.target.value)}
                        required
                        fullWidth
                        autoComplete='off'
                        inputProps={{ style: { textTransform: "uppercase" } }}
                        disabled={DesabilitarCampoCrud()}
                    />
                    <Campo
                        id="descricao"
                        label="Descrição"
                        value={descricao}
                        onChange={(e) => setDescricao(e.target.value)}
                        fullWidth
                        autoComplete='off'
                        inputProps={{ style: { textTransform: "uppercase" } }}
                        disabled={DesabilitarCampoCrud()}
                    />
                    <EsconderPorNivelAcesso nivelAcesso={[NivelAcessoEnum.admin, NivelAcessoEnum.gerente, NivelAcessoEnum.usuario]}>
                        <FormControlEspacado focused variant='outlined' fullWidth>
                            <InputLabelBranco>Valor Compra</InputLabelBranco>
                            <CurrencyInput
                                onValueChange={(v: NumberFormatValues) => setValorCompra(v.floatValue ?? 0)}
                                value={valorCompra}
                                required
                                autoComplete='off'
                            />
                        </FormControlEspacado>
                    </EsconderPorNivelAcesso>
                    <FormControlEspacado focused variant='outlined' fullWidth>
                        <InputLabelBranco>Valor Venda</InputLabelBranco>
                        <CurrencyInput
                            onValueChange={(v: NumberFormatValues) => setValor(v.floatValue ?? 0)}
                            value={valor}
                            required
                            autoComplete='off'
                            disabled={DesabilitarCampoCrud()}
                        />
                    </FormControlEspacado>
                    <FormControlEspacado fullWidth>
                        <InputLabel id="categoria-label">Categoria</InputLabel>
                        <Select
                            labelId="categoria-label"
                            id="categoria"
                            value={categoriaSelecionada}
                            label="Categoria"
                            required
                            disabled={DesabilitarCampoCrud()}
                            onChange={(e: any) => setCategoriaSelecionada(e.target.value as string)}
                        >
                            {
                                categorias?.map((item: Categoria) => {
                                    return <MenuItem key={item.id} value={item.id}>{item.nome}</MenuItem>
                                })
                            }
                        </Select>
                    </FormControlEspacado>
                </Content>
            </DialogContent>
            <ButtonsCrud entidade={entidade} form={'formProduto'} handleDeletar={handleDeletar} handleFechar={handleFechar} handleSalvar={handleSalvar} />
        </Dialog>
    )
}

export default ProdutoDialog
